<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <div class="d-flex flex-column align-items-start">
              <h4 class="card-title"><b>ONBOARDING</b></h4>
            </div>
          </template>
          <div class="px-5 py-3">
            <div class="row" style="gap: 1rem">
              <div class="col-12 col-md-10">
                <!-- linar-style cursor glow -->
                <CardTable :data="dataCards" />
              </div>
              <!-- btn new onboarding -->
              <div class="col-12 col-md">
                <BtnAddCardTable
                  text="Nuevo Onboarding"
                  url="Onboarding_agregar"
                />
              </div>
            </div>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table
              id="datatable_onboarding"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Trabajador</th>
                  <th>Cargo</th>
                  <th>Fecha inicio</th>
                  <th>Fecha fin</th>
                  <th>Descripcion</th>
                  <th>Detalles</th>
                  <th>Estado Onboarding</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>
                    {{ item.persona.nombres + " " + item.persona.apellidos }}
                  </td>
                  <td>{{ item.personaUbicacion.nombre }}</td>
                  <td>{{ item.fechaInicio | formatDate }}</td>
                  <td>{{ item.fechaFin | formatDate }}</td>
                  <td>{{ item.descripcion }}</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <b-button
                        class="d-flex"
                        v-b-modal.my-modal
                        @click="filterData(listaItems, item.id)"
                      >
                        <i class="fas fa-eye"></i
                      ></b-button>
                    </div>
                  </td>
                  <td>
                    <h4>
                      <b-badge
                        :variant="`${item.estado ? 'success' : 'warning'}`"
                      >
                        <i
                          :class="`fas ${
                            item.estado ? 'fa-check' : 'fa-clock'
                          }`"
                        ></i
                        >&nbsp;
                        {{ item.estado ? "Terminado" : "Pendiente" }}
                      </b-badge>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- modal ver mas datalles -->
          <b-modal
            title="Detalles Onboarding"
            id="my-modal"
            size="lg"
            hide-footer
          >
            <h4 class="mb-2">Lista Actividades</h4>
            <template v-if="listaActividades.length">
              <div
                class="mb-2"
                v-for="(item, index) in listaActividades"
                :key="item.id"
              >
                <b-button
                  block
                  class="d-flex justify-content-between align-items-center"
                  v-b-toggle="`collapse-${index}`"
                  variant="primary"
                  ><span class="text-truncate"
                    ><i
                      :class="`fas ${
                        item.actividadProgreso.porcentajeResp == 100
                          ? 'fa-check'
                          : 'fa-clock'
                      }`"
                    ></i
                    >{{ index + 1 }}. {{ item.nombre }}</span
                  >
                  <i class="fas fa-chevron-down"></i
                ></b-button>
                <b-collapse :id="`collapse-${index}`" class="mt-2">
                  <b-card class="position-relative">
                    <small
                      class="position-absolute text-success"
                      style="right: 5px; top: 5px"
                      >último progreso:
                      {{
                        item.actividadProgreso.fechaCreacion | timeSince
                      }}</small
                    >
                    <p class="card-text font-italic">
                      <b>Descripcion: </b>{{ item.descripcion }}
                    </p>
                    <p class="card-text font-italic">
                      <b>Avance actividad: </b
                      >{{ item.actividadProgreso.porcentajeAut }}%
                    </p>
                    <p class="card-text font-italic">
                      <b>Comentario trabajador: </b
                      >{{ item.actividadProgreso.comentarioAut }}
                    </p>
                    <hr />
                    <p class="card-text font-italic">
                      <b class="text-primary">Mi comentario: </b
                      >{{ item.actividadProgreso.comentarioResp }}
                    </p>
                    <!-- actions admin -->
                    <div class="d-flex flex-column" style="gap: 20px">
                      <!-- calificar actividad -->
                      <b-button v-b-toggle="`collapse-${index}-inner`">
                        Evaluar actividad <i class="fas fa-star"></i>
                      </b-button>
                      <b-collapse :id="`collapse-${index}-inner`" class="mt-2">
                        <b-card>
                          <form
                            @submit.prevent="
                              sendActividadProgresoResponsable(item, item.id)
                            "
                          >
                            <div
                              class="d-flex flex-column justify-content-between"
                              style="gap: 20px"
                            >
                              <b-form-textarea
                                class="w-100"
                                v-model="
                                  item.actividadProgreso.newComentarioResp
                                "
                                placeholder="Escriba un comentario..."
                                minlength="10"
                                maxlength="255"
                                required
                              ></b-form-textarea>
                              <div
                                class="d-flex justify-content-center align-items-center"
                              >
                                <input
                                  class="mr-1 w-100"
                                  type="range"
                                  id="porcentaje"
                                  v-model="
                                    item.actividadProgreso.porcentajeResp
                                  "
                                  @input="item.changeInput = true"
                                  min="0"
                                  max="100"
                                  step="0"
                                />
                                <span
                                  style="font-size: 30px"
                                  :class="`px-2 ${
                                    item.actividadProgreso.porcentajeResp < 50
                                      ? 'text-danger'
                                      : item.actividadProgreso.porcentajeResp <
                                        80
                                      ? 'text-warning'
                                      : 'text-primary'
                                  }`"
                                  ><small
                                    >{{
                                      item.actividadProgreso.porcentajeResp
                                    }}%</small
                                  ></span
                                >
                              </div>
                            </div>
                            <div class="text-center mt-3">
                              <b-button
                                block
                                type="submit"
                                variant="outline-light"
                                :disabled="!item.changeInput"
                              >
                                <i class="fas fa-thumbs-up"></i> Evaluar
                              </b-button>
                            </div>
                          </form>
                        </b-card>
                      </b-collapse>
                    </div>
                  </b-card>
                </b-collapse>
              </div>
            </template>
            <p v-else>cargando actividades...</p>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import { core } from "../../../config/pluginInit";
import moment_timezone from "moment-timezone";
import CardTable from "../../../components/cardTable/CardTable.vue";
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
const Swal = require("sweetalert2");
export default {
  name: "Onboarding_admin",
  data() {
    return {
      listaItems: [],
      listaActividades: [],
      evaluarActividad: 0,
      persona: {},
      actividadProgresoResponsable: {
        onboardingAdminId: null,
        actividadOnboardingId: null,
        porcentajeAut: 0,
        comentarioAut: "",
        porcentajeResp: 0,
        comentarioResp: "",
        fechaCreacion: null,
        fechaCierre: null,
      },
      dataCards: [
        {
          title: "Terminandos",
          value: () => this.listaItems.filter((plan) => plan.estado).length,
          iconName: "calendar-check",
          color: "rgba(22, 162, 117, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Terminado").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Pendientes",
          value: () => this.listaItems.filter((plan) => !plan.estado).length,
          iconName: "clock",
          color: "rgba(254, 114, 28, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Pendiente").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Total actividades",
          value: () =>
            this.listaItems.reduce(
              (totalAct, plan) =>
                totalAct + plan.actividadesId.split(",").length,
              0
            ),
          iconName: "bolt",
          color: "rgba(71, 136, 255, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => console.log("Activated"),
          onDeactivate: () => console.log("Deactivate"),
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    core.index();
    if (window.$.fn.DataTable.isDataTable("#datatable_onboarding")) {
      window.$("#datatable_onboarding").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#datatable_onboarding").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    ...mapMutations(["setPendientesOnboardings"]),
    updatePendientesOnboardings(newValue) {
      this.setPendientesOnboardings(newValue);
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `${
            this.persona.rol.nivel == 4
              ? "OnboardingAdmin/"
              : "OnboardingAdmin/ByResponsableId/" + this.persona.id
          }`,
        });
        console.log("list onboardings...", res);
        if (res.length > 0) {
          // Utilizamos Promise.all para esperar a que todas las promesas se resuelvan
          const updatedItems = await Promise.all(
            res.map(async (onboarding) => {
              let ubicacion = await this.getPersonaUbicacion(
                onboarding.persona.id
              );
              console.log("-------->cargo", ubicacion);
              return {
                ...onboarding,
                personaUbicacion: ubicacion,
              };
            })
          );
          // Ahora updatedItems es un array de objetos de onboarding que han sido enriquecidos con personaUbicacion
          this.listaItems = updatedItems;
          const pendientesOnboardings = this.listaItems.filter((onboarding) => {
            console.log("--------------------onboarding", onboarding);
            return onboarding.estado == 0;
          });
          this.updatePendientesOnboardings(pendientesOnboardings.length);
        } else {
          this.updatePendientesOnboardings(0);
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
    async sendActividadProgresoResponsable(item, actividadOnboardingId) {
      console.log(this.listaActividades);
      try {
        let data = {
          ...this.actividadProgresoResponsable,
          porcentajeResp: item.actividadProgreso.porcentajeResp,
          comentarioResp: item.actividadProgreso.newComentarioResp,
          fechaCreacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
          fechaCierre: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
          actividadOnboardingId,
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "ActividadOnboarding/CreateActividadProgresoResponsable/",
          data,
        });
        console.log(res);
        if (res) {
          this.actividadProgresoResponsable.fechaCreacion = this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss");
          // Actualiza el progreso de la actividad en el cliente
          let actividadIndex = this.listaActividades.findIndex(
            (a) => a.id === actividadOnboardingId
          );
          if (actividadIndex !== -1) {
            this.listaActividades[actividadIndex].actividadProgreso = {
              ...this.listaActividades[actividadIndex].actividadProgreso,
              porcentajeResp: item.actividadProgreso.porcentajeResp,
              comentarioResp: item.actividadProgreso.newComentarioResp,
              fechaCreacion: this.actividadProgresoResponsable.fechaCreacion,
            };
          }
          Swal.fire({
            title: "Listo!",
            text: "Evaluacion registrada correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              item.actividadProgreso.newComentarioResp = "";
              item.changeInput = false;
              const todasEn100 = this.listaActividades.every(
                (actividad) => actividad.actividadProgreso.porcentajeResp == 100
              );
              // Luego del bucle, actuamos según si todas las actividades estaban en 100 o no
              if (todasEn100) {
                console.log(
                  "Todas las actividades tienen un porcentajeResp de 100"
                );
                this.updateEstadoOnboarding(
                  this.actividadProgresoResponsable.onboardingAdminId,
                  { NuevoEstado: 1 }
                );
                this.listaItems.forEach((onboarding) => {
                  if (
                    onboarding.id ==
                    this.actividadProgresoResponsable.onboardingAdminId
                  )
                    onboarding.estado = 1;
                });
                const pendientesOnboardings = this.listaItems.filter(
                  (onboarding) => {
                    console.log("--------------------onboarding", onboarding);
                    return onboarding.estado == 0;
                  }
                );
                this.updatePendientesOnboardings(pendientesOnboardings.length);
              } else {
                console.log(
                  "No todas las actividades tienen un porcentajeResp de 100"
                );
                this.updateEstadoOnboarding(
                  this.actividadProgresoResponsable.onboardingAdminId,
                  { NuevoEstado: 0 }
                );
                this.listaItems.forEach((onboarding) => {
                  if (
                    onboarding.id ==
                    this.actividadProgresoResponsable.onboardingAdminId
                  )
                    onboarding.estado = 0;
                });
                const pendientesOnboardings = this.listaItems.filter(
                  (onboarding) => {
                    console.log("--------------------onboarding", onboarding);
                    return onboarding.estado == 0;
                  }
                );
                this.updatePendientesOnboardings(pendientesOnboardings.length);
              }
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getActividadById(actividadId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "ActividadOnboarding/GetActividad/" + actividadId,
        });
        console.log("actividadById...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersonaUbicacion(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        console.log("personaUbicacion...", res);
        this.personaUbicacion = res;
        return res.cargo;
      } catch (error) {
        this.personaUbicacion = null;
        console.log("err", error);
      }
    },
    async getActividadByOnboardingAdminIdAndActividadOnboardingId(
      onboardingAdminId,
      actividadOnboardingId
    ) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "ActividadProgresoOnboarding/GetActividadByOnboardingIdAndActividadOnboardingId/" +
            onboardingAdminId +
            "/" +
            actividadOnboardingId,
        });
        console.log("ActividadProgreso...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async fetchActividadProgresoForActividades(onboardingAdminId, actividades) {
      let actividadProgresoPromises = actividades.map((actividad) =>
        this.getActividadByOnboardingAdminIdAndActividadOnboardingId(
          onboardingAdminId,
          actividad.id
        )
      );
      return await Promise.all(actividadProgresoPromises);
    },
    async fetchAllDataParallel(data) {
      let dataPromises = data.map((id) => this.getActividadById(id));
      return await Promise.all(dataPromises);
    },
    async updateEstadoOnboarding(onboardingAdminId, data) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "OnboardingAdmin/UpdateEstado/" + onboardingAdminId,
          data,
        });
        console.log(res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    filterData(data, id) {
      this.actividadProgresoResponsable.onboardingAdminId = id;
      let dataFilter = data.filter((item) => item.id === id);
      let actividades = dataFilter[0].actividadesId.split(",");
      console.log(actividades);
      this.fetchAllDataParallel(actividades).then(async (result) => {
        // Aquí obtenemos actividadProgreso para cada actividad en result
        let actividadProgresoResults =
          await this.fetchActividadProgresoForActividades(id, result);
        for (let i = 0; i < result.length; i++) {
          result[i].actividadProgreso = actividadProgresoResults[i];
          result[i].actividadProgreso.changeInput = false;
          result[i].actividadProgreso.newComentarioResp = "";
        }
        this.listaActividades = result;
        console.log(result);
      });
    },
  },
  filters: {
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("es-ES");
      return formattedDate;
    },
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm A");
    },
  },
  components: { CardTable, BtnAddCardTable },
};
</script>

<style></style>
