var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-items-start"},[_c('h4',{staticClass:"card-title"},[_c('b',[_vm._v("ONBOARDING")])])])]},proxy:true}])},[_c('div',{staticClass:"px-5 py-3"},[_c('div',{staticClass:"row",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"col-12 col-md-10"},[_c('CardTable',{attrs:{"data":_vm.dataCards}})],1),_c('div',{staticClass:"col-12 col-md"},[_c('BtnAddCardTable',{attrs:{"text":"Nuevo Onboarding","url":"Onboarding_agregar"}})],1)])]),_c('div',{staticClass:"table-responsive px-4 mt-3"},[_c('table',{staticClass:"table table-bordered table-responsive-md table-striped text-center",attrs:{"id":"datatable_onboarding"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Trabajador")]),_c('th',[_vm._v("Cargo")]),_c('th',[_vm._v("Fecha inicio")]),_c('th',[_vm._v("Fecha fin")]),_c('th',[_vm._v("Descripcion")]),_c('th',[_vm._v("Detalles")]),_c('th',[_vm._v("Estado Onboarding")])])]),_c('tbody',_vm._l((_vm.listaItems),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(" "+_vm._s(item.persona.nombres + " " + item.persona.apellidos)+" ")]),_c('td',[_vm._v(_vm._s(item.personaUbicacion.nombre))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechaInicio)))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.fechaFin)))]),_c('td',[_vm._v(_vm._s(item.descripcion))]),_c('td',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.my-modal",modifiers:{"my-modal":true}}],staticClass:"d-flex",on:{"click":function($event){return _vm.filterData(_vm.listaItems, item.id)}}},[_c('i',{staticClass:"fas fa-eye"})])],1)]),_c('td',[_c('h4',[_c('b-badge',{attrs:{"variant":`${item.estado ? 'success' : 'warning'}`}},[_c('i',{class:`fas ${
                          item.estado ? 'fa-check' : 'fa-clock'
                        }`}),_vm._v("  "+_vm._s(item.estado ? "Terminado" : "Pendiente")+" ")])],1)])])}),0)])]),_c('b-modal',{attrs:{"title":"Detalles Onboarding","id":"my-modal","size":"lg","hide-footer":""}},[_c('h4',{staticClass:"mb-2"},[_vm._v("Lista Actividades")]),(_vm.listaActividades.length)?_vm._l((_vm.listaActividades),function(item,index){return _c('div',{key:item.id,staticClass:"mb-2"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${index}`),expression:"`collapse-${index}`"}],staticClass:"d-flex justify-content-between align-items-center",attrs:{"block":"","variant":"primary"}},[_c('span',{staticClass:"text-truncate"},[_c('i',{class:`fas ${
                      item.actividadProgreso.porcentajeResp == 100
                        ? 'fa-check'
                        : 'fa-clock'
                    }`}),_vm._v(_vm._s(index + 1)+". "+_vm._s(item.nombre))]),_c('i',{staticClass:"fas fa-chevron-down"})]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`collapse-${index}`}},[_c('b-card',{staticClass:"position-relative"},[_c('small',{staticClass:"position-absolute text-success",staticStyle:{"right":"5px","top":"5px"}},[_vm._v("último progreso: "+_vm._s(_vm._f("timeSince")(item.actividadProgreso.fechaCreacion)))]),_c('p',{staticClass:"card-text font-italic"},[_c('b',[_vm._v("Descripcion: ")]),_vm._v(_vm._s(item.descripcion)+" ")]),_c('p',{staticClass:"card-text font-italic"},[_c('b',[_vm._v("Avance actividad: ")]),_vm._v(_vm._s(item.actividadProgreso.porcentajeAut)+"% ")]),_c('p',{staticClass:"card-text font-italic"},[_c('b',[_vm._v("Comentario trabajador: ")]),_vm._v(_vm._s(item.actividadProgreso.comentarioAut)+" ")]),_c('hr'),_c('p',{staticClass:"card-text font-italic"},[_c('b',{staticClass:"text-primary"},[_vm._v("Mi comentario: ")]),_vm._v(_vm._s(item.actividadProgreso.comentarioResp)+" ")]),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"20px"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${index}-inner`),expression:"`collapse-${index}-inner`"}]},[_vm._v(" Evaluar actividad "),_c('i',{staticClass:"fas fa-star"})]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`collapse-${index}-inner`}},[_c('b-card',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendActividadProgresoResponsable(item, item.id)}}},[_c('div',{staticClass:"d-flex flex-column justify-content-between",staticStyle:{"gap":"20px"}},[_c('b-form-textarea',{staticClass:"w-100",attrs:{"placeholder":"Escriba un comentario...","minlength":"10","maxlength":"255","required":""},model:{value:(
                                item.actividadProgreso.newComentarioResp
                              ),callback:function ($$v) {_vm.$set(item.actividadProgreso, "newComentarioResp", $$v)},expression:"\n                                item.actividadProgreso.newComentarioResp\n                              "}}),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                  item.actividadProgreso.porcentajeResp
                                ),expression:"\n                                  item.actividadProgreso.porcentajeResp\n                                "}],staticClass:"mr-1 w-100",attrs:{"type":"range","id":"porcentaje","min":"0","max":"100","step":"0"},domProps:{"value":(
                                  item.actividadProgreso.porcentajeResp
                                )},on:{"input":function($event){item.changeInput = true},"__r":function($event){return _vm.$set(item.actividadProgreso, "porcentajeResp", $event.target.value)}}}),_c('span',{class:`px-2 ${
                                  item.actividadProgreso.porcentajeResp < 50
                                    ? 'text-danger'
                                    : item.actividadProgreso.porcentajeResp <
                                      80
                                    ? 'text-warning'
                                    : 'text-primary'
                                }`,staticStyle:{"font-size":"30px"}},[_c('small',[_vm._v(_vm._s(item.actividadProgreso.porcentajeResp)+"%")])])])],1),_c('div',{staticClass:"text-center mt-3"},[_c('b-button',{attrs:{"block":"","type":"submit","variant":"outline-light","disabled":!item.changeInput}},[_c('i',{staticClass:"fas fa-thumbs-up"}),_vm._v(" Evaluar ")])],1)])])],1)],1)])],1)],1)}):_c('p',[_vm._v("cargando actividades...")])],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }